<template>
  <v-container id="Login" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-3 font-weight-bold">Login</div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Email Address"
                    hint="Email Address"
                    v-model="form.email"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Password"
                    :type="show_pass ? 'text' : 'password'"
                    hint="Password"
                    :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_pass = !show_pass"
                    v-model="form.password"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <vue-recaptcha
                    :sitekey="siteKey"
                    ref="recaptcha"
                    @verify="onVerify"
                  ></vue-recaptcha>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="2">
                  <p>Not yet registered?
                  <v-btn small to="/register" >Register here</v-btn>
                </p>
                </v-col>
                  
                <v-col cols="4" class="text-right" md="2">
                  <v-btn
                    color="success"
                    class="display-1 font-weight-bold"
                    @click="login()"
                    :loading="loading"
                    >Login</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Login",
  data: () => ({
    show_pass: false,
    form: {
      email: "",
      password: "",
      robot: true,
    },
    loading: false,
    siteKey: "6LfRCbwhAAAAAN2ERRMfNxIPYGtdHzlS0ZYrabox",
  }),

  components: {
    VueRecaptcha,
  },
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch("loginUser", this.form);

      //if successfull login
      if (await this.$store.state.login == true) {
        await this.$router.push({ name: "Agricultural Commodities" });
        await this.$toast.success("Login Successfully");
      } else {
        //if failed login
        await this.$toast.error("Failed Login");
      }
      this.loading = false;
    },

    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },

    onVerify(response) {
      if (response) {
        this.form.robot = false;
      } else {
        this.form.robot = true;
      }
    },
  },
};
</script>
